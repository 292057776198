<template>
  <div class="cultivation-details">
    <div class="details-wrapper">
      <CultivationDetails :cultivation-id="cultivationId" :year="year" />
      <CultivationMixtureComponents />
      <CultivationYield :cultivation-id="cultivationId" :year="year" />
      <CultivationActions :cultivation-id="cultivationId" :year="year" />
      <CultivationAudit :cultivation-id="cultivationId" />
    </div>
    <div class="button-wrapper">
      <IxButton large edit @click="edit" />
      <IxButton large remove @click="remove" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import {notifications} from 'src/js/infrastructure'

import IxButton from '@components/IxButton'

import CultivationDetails from './CultivationDetails'
import CultivationMixtureComponents from './CultivationMixtureComponents'
import CultivationYield from './CultivationYield'
import CultivationActions from './CultivationActions'
import CultivationAudit from './CultivationAudit'

export default {
  components: {
    IxButton,
    CultivationActions,
    CultivationYield,
    CultivationMixtureComponents,
    CultivationDetails,
    CultivationAudit
  },
  props: {
    cultivationId: {
      type: String,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'cultivationDataLookup'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationOverview', [
      'deleteCultivation',
      'toggleCultivation'
    ]),
    ...mapActions('fieldRecordSystem', [
      'cultivationPlanningOrgUnit'
    ]),
    edit () {
      this.$router.push({name: 'orgDashboard'})
      this.cultivationPlanningOrgUnit({cultivationId: this.cultivationId})
    },
    remove () {
      this.deleteCultivation(this.cultivationId)
        .then(() => {
          notifications.success(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationDeleteSuccess'))
        })
        .catch(error => {
          notifications.error(this.$i18n.translate('Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationDeleteError'))
          console.error(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 0 8px;

  ::v-deep .collapse-panel {
    padding: 0;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .button-wrapper {
    display: flex;

    button {
      flex: 1;
    }

    button:first-child {
      margin-right: 8px;
    }
  }
}
</style>
