<template>
  <IxCollapsePanel v-if="hasMixtureComponents" v-model="showMixtureComponents">
    <template #title>
      <IxRes>cultivation.titles.mixtureComponents</IxRes>
    </template>
    <FrsMixtureTable
      :mixture-components="selectedCultivationData[0].mixtureComponents"
    />
  </IxCollapsePanel>
</template>

<script>
import {mapGetters} from 'vuex'

import {mapUiFlags} from '@frs/helpers/ui'

import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import FrsMixtureTable from '@frs/components/base/FrsMixtureTable'

export default {
  components: {
    IxCollapsePanel,
    FrsMixtureTable
  },
  computed: {
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'selectedCultivationData'
    ]),
    ...mapUiFlags([
      'cultivation.showMixtureComponents'
    ]),
    hasMixtureComponents () {
      return this.selectedCultivationData[0].mixtureComponents && this.selectedCultivationData[0].mixtureComponents.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
