<template>
  <div v-if="details">
    <h3>
      <IxRes>cultivation.titles.details</IxRes>
    </h3>
    <h4>{{ details.label }}</h4>
    <LabelAndMessage>
      <template #label>
        <IxRes>cultivation.labels.cropUsage</IxRes>:
      </template>
      <span>&nbsp;</span>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_{{ details.type }}</IxRes>
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>cultivation.labels.timeRange</IxRes>:
      </template>
      {{ cultivationDateRange }}
    </LabelAndMessage>
    <LabelAndMessage>
      <template #label>
        <IxRes>cultivation.labels.area</IxRes>:
      </template>
      <span>&nbsp;</span>
      <IxRes :context="{area: this.$i18n.format(details.area, 'area')}">cultivation.descriptions.areaWithDescription</IxRes>
    </LabelAndMessage>
    <div class="details-actions">
      <IxButton @click="selectSameCropUsages">
        <IxRes>cultivation.select.sameCropUsages</IxRes>
      </IxButton>
      <IxButton @click="selectSameField">
        <IxRes>cultivation.select.sameField</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {difference} from 'lodash'
import {smarterGet} from '@helpers/vuex/data-loading'
import {cultivationToColor, cultivationToName, cultivationTypeToShortcut} from '../helpers'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import IxButton from '@components/IxButton'

export default {
  components: {
    LabelAndMessage,
    IxButton
  },
  props: {
    cultivationId: {
      type: String,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      details: null
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/cultivationOverview', [
      'cultivationData',
      'selectedCultivationData'
    ]),
    cultivationDateRange () {
      return `${this.$i18n.format(this.details.dateTimeRange.start, 'date')} - ${this.$i18n.format(this.details.dateTimeRange.end, 'date')}`
    },
    cultivationIdsForSameFields () {
      return this.cultivationData.filter(cultivation => cultivation.fieldIds.some(id => this.selectedCultivationData[0].fieldIds.includes(id))).map(x => x.id)
    },
    cultivationIdsForLabel () {
      return this.cultivationData.filter(cultivation => cultivation.label === this.details.label).map(x => x.id)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationOverview', [
      'toggleCultivation'
    ]),
    selectSameCropUsages () {
      this.toggleCultivationWithoutSelected(this.cultivationIdsForLabel)
    },
    selectSameField () {
      this.toggleCultivationWithoutSelected(this.cultivationIdsForSameFields)
    },
    toggleCultivationWithoutSelected (cultivationIds) {
      difference(cultivationIds, [this.selectedCultivationData[0].id]).forEach(id => {
        this.toggleCultivation(id)
      })
    }
  },
  created () {
    smarterGet('/api/v2/org/cultivations/{cultivationId}/details', {
      id: 'orgUnit.cultivationsV2.loadDetails',
      inputs: {
        cultivationId: () => this.cultivationId
      },
      onResult: details => {
        this.details = {
          ...details,
          color: cultivationToColor(details),
          label: cultivationToName(details),
          typeShortcut: cultivationTypeToShortcut(details)
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.details-actions {
  margin: 7px 0 0 0;

  > * {
    margin-right: 5px;
  }
}

</style>
