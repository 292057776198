<template>
  <IxCollapsePanel v-if="yields" v-model="showCultivationYield">
    <template #title>
      <IxRes>cultivation.titles.yield</IxRes>
    </template>
    <SimpleTable
      v-if="yieldData"
      :columns="yieldColumns"
      :data="yieldData"
    />
  </IxCollapsePanel>
</template>

<script>
import {sumBy, uniq, head} from 'lodash'

import {notifications} from 'src/js/infrastructure'
import {mapUiFlags} from '@frs/helpers/ui'

import {smarterGet} from '@helpers/vuex/data-loading'

import SimpleTable from '@components/table/SimpleTable'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'

export default {
  components: {
    SimpleTable,
    IxCollapsePanel
  },
  props: {
    cultivationId: {
      type: String,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      yields: null
    }
  },
  computed: {
    ...mapUiFlags([
      'cultivation.showCultivationYield'
    ]),
    yieldColumns () {
      return {
        expectedYield: this.$i18n.translate('cultivation.tables.headers.expectedYield'),
        mainCropYield: this.$i18n.translate('cultivation.tables.headers.mainCropYield'),
        catchCropYield: this.$i18n.translate('cultivation.tables.headers.catchCropYield')
      }
    },
    yieldData () {
      if (!this.yields) return null
      const expectedYield = this.yields.expectedYieldAmount
        ? `${this.yields.expectedYieldAmount} ${this.yields.expectedYieldUnit}`
        : this.yields.expectedYieldAmount
      const mainCropYieldSum = sumBy(this.yields.parameters, 'mainCropYield.amount')
      const mainCropYield = mainCropYieldSum
        ? `${mainCropYieldSum} ${head(uniq(this.yields.parameters.map(x => x.mainCropYield.unit)))}`
        : mainCropYieldSum
      const catchCropYieldSum = sumBy(this.yields.parameters, 'catchCropYield.amount')
      const catchCropYield = catchCropYieldSum
        ? `${catchCropYieldSum} ${head(uniq(this.yields.parameters.map(x => x.catchCropYield.unit)))}`
        : catchCropYieldSum

      return [{
        expectedYield,
        mainCropYield,
        catchCropYield
      }]
    }
  },
  created () {
    smarterGet('/api/v2/{harvestYear}/org/cultivations/{cultivationId}/yield', {
      id: 'orgUnit.cultivationsV2.loadYieldDetails',
      inputs: {
        harvestYear: () => this.year,
        cultivationId: () => this.cultivationId
      },
      onResult: details => {
        this.yields = details
      }
    })
    .catch(error => {
      notifications.error(this.SR_StateMessages.SaveError)
      console.error(error)
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
