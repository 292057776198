<template>
  <IxCollapsePanel v-if="actions.length" v-model="showCultivationActions">
    <template #title>
      <IxRes>cultivation.titles.actions</IxRes>
    </template>
    <ActionCard
      v-for="(action, index) in plannedActions.concat(actualActions)" :key="index"
      v-bind="{action, products, personnel, machines}"
      class="action-item"
    />
  </IxCollapsePanel>
</template>

<script>
import {orderBy} from 'lodash'

import {smarterGet} from '@helpers/vuex/data-loading'
import {mapState, mapGetters} from 'vuex'
import {mapUiFlags} from '@frs/helpers/ui'

import IxCollapsePanel from '@components/ix/IxCollapsePanel'
import ActionCard from '@frs/components/action/ActionCard'

export default {
  components: {
    IxCollapsePanel,
    ActionCard
  },
  props: {
    cultivationId: {
      type: String,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      actions: []
    }
  },
  computed: {
    ...mapUiFlags([
      'cultivation.showCultivationActions'
    ]),
    ...mapState('fieldRecordSystem', {
      machines: state => state.machines.data.machines,
      personnel: state => state.persons.data.persons
    }),
    ...mapGetters('masterData', [
      'products'
    ]),
    actualActions () {
      return orderBy(this.actions.filter(x => x.timestamps.actual.start || x.timestamps.actual.end), 'timestamps.actual.start', 'asc')
    },
    plannedActions () {
      return orderBy(this.actions.filter(x => !x.timestamps.actual.start && !x.timestamps.actual.end), 'timestamps.planned.start', 'asc')
    },
  },
  async created () {
    smarterGet('/api/v2/{harvestYear}/cultivations/{cultivationId}/actions', {
      id: 'orgUnit.cultivationsV2.loadActions',
      inputs: {
        harvestYear: () => this.year,
        cultivationId: () => this.cultivationId
      },
      onResult: actions => {
        this.actions = actions
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.action-item {
  margin-bottom: 4px;
}
</style>
