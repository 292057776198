<template>
  <div v-if="audit" class="audit">
    <ShowDetails>
      <template #label>
        <IxRes>frs.action.details.updated</IxRes>:
      </template>

      {{ audit.updatedBy.toLowerCase() }}, {{ $i18n.format(audit.updatedAt, 'datetime-short') }}
    </ShowDetails>
    <ShowDetails>
      <template #label>
        <IxRes>frs.action.details.created</IxRes>:
      </template>

      {{ audit.createdBy.toLowerCase() }}, {{ $i18n.format(audit.createdAt, 'datetime-short') }}
    </ShowDetails>
  </div>
</template>

<script>
import {smarterGet} from '@helpers/vuex/data-loading'

import ShowDetails from '@frs/components/action/planning/wizard/details/ShowDetails'

export default {
  components: {
    ShowDetails
  },
  props: {
    cultivationId: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      audit: null
    }
  },
  created () {
    smarterGet('/api/v2/org/cultivations/{cultivationId}/audit', {
      id: 'orgUnit.cultivationsV2.loadAudit',
      inputs: {
        cultivationId: () => this.cultivationId
      },
      onResult: audit => {
        this.audit = audit
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.audit {
  font-size: 0.8em;
}
</style>
